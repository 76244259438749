<template>
  <form class="mt-4" @submit.prevent="onSubmit">
    <h2>Resend Link</h2>
    <p class="text-justify">Clicking the 'Resend Link' button will send another email containing the verification link to the email address associated with your account. </p>

    <div class="d-inline-block w-100">
      <button :disabled="isSubmitting" type="submit" class="btn btn-primary float-left">
        {{ isSubmitting ? 'Submitting...' : 'Resend Link' }}
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import constant, { toastMessage } from "@/config/constant";

export default {
  name: 'ConfirmMail',
  data() {
    return {
      isSubmitting: false, // Define isSubmitting in the data object
      encryptedToken: '',
      encryptedLinkType: '',
      message: ''
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.encryptedToken = urlParams.get('token');
    this.encryptedLinkType = urlParams.get('link_type');
  },
  methods: {
    onSubmit() {
      //Resend link send
      this.isSubmitting = true; // Enable the submit button
      axios.post(constant.API_BASE_URL + 'resend-verification-link/', {
        token: this.encryptedToken,
        linkType: this.encryptedLinkType
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          this.message = response.data.message;
          this.icon = "success";
          toastMessage(this.icon, this.message);
        })
        .catch(error => {
          this.message = error.response.data.message;
          this.icon = "success";
          toastMessage(this.icon, this.message);
        }).finally(() => {
          this.isSubmitting = false; // Enable submit button
          this.message = "Something is went wrong";
          this.icon = "error";
          toastMessage(this.icon, this.message);
        });
      this.message = ''; // Clear the message after submission
    }
  }
}
</script>
